@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Sixtyfour&display=swap");

/********** Template CSS **********/
:root {
  --primary: #f08233;
  --secondary: #dca47c;
  --light: #f4f7fe;
  --dark: #70350c;
}

/* 
    --primary: #1363C6;
    --secondary: #15ACE1;
    --light: #F4F7FE;
    --dark: #14183E;
*/

body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  justify-content: space-around;
  min-height: 200vh;
  margin: 0;
  padding: 0;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Heading ***/

h2,
h3,
.fw-bold {
  font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Navbar ***/
.sticky-top {
  top: -150px;
  transition: 0.5s;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

/*** Hero Header ***/
.hero-header {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -75px;
  background: url(../img/RScomnetworkBackground.png) center center no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -2;
  
}

.hero-header::before {
  content: "";
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.slider-container {
  position: absolute;
}
.hero-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

/*** Section Title ***/
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 4px;
  bottom: 0;
  left: 0;
  background: var(--dark);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  bottom: 0;
  left: 50px;
  background: var(--dark);
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -25px;
}

.section-title.text-center::after {
  left: 50%;
  margin-left: 25px;
}

.section-title h6::before,
.section-title h6::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 2px;
  left: 0;
  background: rgba(33, 66, 177, 0.5);
}

.section-title h6::after {
  top: 5px;
  left: 3px;
}

/*** About ***/
.about-img {
  position: relative;
  overflow: hidden;
}

.about-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../img/bg-about-img.png) top left no-repeat;
  background-size: contain;
}

/*** Service ***/
.service-item {
  position: relative;
  padding: 45px 30px;
  background: #ffffff;
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  color: var(--primary);
  background: var(--light);
  transition: 0.5s;
}

.service-item:hover .service-icon {
  background: #ffffff;
}

.service-item h5,
.service-item p {
  transition: 0.5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: #ffffff;
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: 0.5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: var(--primary);
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: var(--light);
  transition: 0.5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}

/*** Feature ***/
.feature {
  background: url(../img/dynamic_wireframe_particle_landscape_background.jpg)
    center center no-repeat;
  height: 390px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 767px) {
    height: auto;
    .font-chose {
      position: relative;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: auto;
  }
  .font-chose {
    position: relative;
  }
}

.feature::before {
  content: "";
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.font-chose {
  position: absolute;
}

/*** Newsletter ***/
.newsletter {
  background: url(../img/dynamic_wireframe_particle_landscape_background.jpg)
    center center no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .newsletter .container {
    max-width: 100% !important;
  }

  .newsletter .newsletter-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .newsletter .newsletter-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .newsletter .newsletter-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Case Study ***/
.case-item img {
  transition: 0.5s;
}

.case-item:hover img {
  transform: scale(1.2);
}

.case-overlay {
  position: absolute;
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: linear-gradient(rgba(20, 24, 62, 0), var(--dark));
  z-index: 1;
}

.case-overlay small {
  display: inline-block;
  padding: 3px 15px;
  color: #ffffff;
  background: rgba(20, 24, 62, 0.7);
  border-radius: 25px;
  margin-bottom: 15px;
}

.case-overlay span.btn:hover {
  color: var(--primary);
  background: #ffffff;
  border-color: #ffffff;
}

/*** FAQs ***/
.accordion .accordion-item {
  border: none;
  margin-bottom: 15px;
}

.accordion .accordion-button {
  background: var(--light);
  border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
  color: #ffffff;
  background: var(--primary);
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
  padding: 15px 0 0 0;
}

/*** Testimonial ***/
.testimonial-carousel {
  position: relative;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 40px;
  height: 100%;
  top: calc(50% - 50px);
  left: -21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 5px 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #ffffff;
  border: 1px solid var(--primary);
  border-radius: 40px;
  font-size: 18px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: #ffffff;
}

.testimonial-carousel .owl-dots {
  margin-top: 35px;
  margin-left: 3rem;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  width: 30px;
  background: var(--primary);
}

/*** Team ***/
.team-item {
  transition: 0.5s;
  border: 1px solid transparent;
  transition: 0.5s;
}

.team-item:hover {
  border-color: var(--primary);
}

/*** Footer ***/
.footer {
  background: url(../img/footer.png) center center no-repeat;
  background-size: contain;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(256, 256, 256, 0.1);
  border-radius: 40px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  transition: 0.3s;
}

.footer .btn.btn-link:hover {
  color: #ffffff;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: rgba(255, 255, 255, 0.5);
}

.footer .copyright a:hover {
  color: #ffffff;
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.text-warningS {
  color: #f08233;
}

.bg-warningS {
  background-color: #dca47c;
}

.slider-container {
  width: 100%;
  position: relative;
}

.slide2 {
  display: block;
}

.active-slide {
  display: block;
}

/* Slayt geçiş efekti */
.slide-fade {
  animation: fade 1s ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Diğer stiller */

/* İleri ve geri tuşları */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Diğer stiller */

/* Slayt noktaları */
.slide-dots {
  text-align: left;
  padding: 10px;
}

.dot {
  cursor: pointer;
  display: inline-block;
  height: 2px;
  width: 5%;
  margin: 0 5px;
  background-color: #bbb;
  transition: background-color 0.3s; /* Geçiş efekti ekleyelim */
}

.active-dot {
  background-color: #f08233; /* Aktif nokta için canlı neon ışıkları rengi */
  box-shadow: 0 0 10px #f08233, 0 0 20px #f08233, 0 0 30px #f08233; /* Canlı neon ışıkları efekti */
}

.Logo {
  margin-top: 10px;
  background: none;
  width: 100px;
  @media only screen and (max-width: 767px) {
    width: 250px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 150px;
  }
}

.navbar-smala {
  background-color: #000;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
}

.carousel {
  width: 100%;
  margin: auto;
}

.carousel-inner img {
  width: 100%;
  height: auto;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
}

.carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: black;
}
.carousel-item {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.carousel-item.active {
  opacity: 1;
}

.Products {
  display: none;
  position: absolute;
  width: 500px;
  z-index: 1;
}
.products_item:hover.Products {
  display: block;
  position: fixed;
  width: 500px;
}

.hero-header {
  height: 350px;
  @media only screen and (max-width: 767px) {
    height: auto;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: auto;
  }
}
.more-about-us-home {
  display: none;
}

/* Style for larger monitors */
@media screen and (min-width: 1300px) {
  /* Add your styles here */
  .more-about-us-home {
    display: block;
  }
}


.mySlides{
    display: none;
}
.mysltactive{
    display: block;
}

.wrapper{
    z-index: 999;
}

a{
  z-index: 9999;
}